const merge = require('lodash/merge')

const { fr } = require('./i18n/config')
const { getApiEnvFromHostname } = require('./utils/url')

const MOCK_HOST = process.env.REACT_APP_MOCK_HOST
const MOCK_PORT = process.env.REACT_APP_MOCK_PORT
const DEVSRV_PORT = process.env.REACT_APP_DEVSRV_PORT
const SCRIPT_ENV = process.env.REACT_APP_SCRIPT_ENV

const apiUrls = {
  qa1: 'https://api.qa1.quotatis.com',
  qa: 'https://api.qa.quotatis.com',
  preprod: 'https://api.preprod.quotatis.com',
  prod: 'https://api.prod.quotatis.com',
}

const currentEnv = SCRIPT_ENV ?? getApiEnvFromHostname()

const configs = {
  all: {
    env: currentEnv,
    api: {
      url: 'https://api.qarx.io',
      clientId: '4qhq3n20xi4gww0gokc0k44k0ss48ssw4g88kgg8kkkscgco0k',
      clientSecret: '4aoyh39n19usgos8ss0osscwg8ogkgkg0wcw0wkkg0kkow8gwc',
      kongApiKey: 'Mhei5u7gzROiAaCQ1JXFXZJvamUie74E',
    },
    dataGouv: {
      enterprise_api_url: 'https://entreprise.data.gouv.fr/api/',
    },
    sylius: {
      url: apiUrls.qa,
    },
    proApi: {
      url: `${apiUrls.qa}/pro`,
    },
    hoApi: {
      url: `${apiUrls.qa}/home-owner`,
    },
    engine: {
      url: apiUrls.qa,
    },
    billing: {
      url: `${apiUrls.qa}/billing`,
    },
    documentsApi: {
      url: `${apiUrls.qa}/documents`,
    },
    frontApi: {
      url: `${apiUrls.qa}/front-api`,
    },
    survey: {
      url: `${apiUrls.qa}/survey`,
    },
    youSign: {
      signatureUis: '/signature_uis/9588b17f-90bc-49df-8bc1-e7cb5d796638',
    },
    axeptio: {
      clientId: '61af807ccca75d12de174daa',
    },
    google: {
      reCaptchaKey: '6Le_7F4aAAAAAEiOhDH3V2zVcLImXxVJJYvNaMXN',
      mapsKey: 'AIzaSyCYrFIvVAYqnWCNjjAfGIanmwxVvj1FPj8',
      grantType: 'https://www.quotatis.com/google',
      clientId:
        '726740106708-5fh1f42gq45ek5a5g2p7olldjt679qjg.apps.googleusercontent.com',
      scope: 'https://www.googleapis.com/auth/plus.login',
      tagManagerId: {
        FR: 'GTM-N58NQW',
        ES: 'GTM-K9GWBNM',
      },
      centerGeometry: {
        FR: {
          lat: 46.8152,
          lng: 2.3988,
        },
        ES: {
          lat: 40.4637,
          lng: -3.7492,
        },
      },
      gtagTrackPose: 'G-FMB7PLX0GJ',
    },
    intercom: {
      appId: 'swltzwn8',
    },
    facebook: {
      grantType: 'https://www.quotatis.com/facebook',
      appId: '233630107058419',
      scope: 'public_profile, email, user_birthday, user_location',
    },
    algolia: {
      search: {
        appId: 'TIM81UW1UV',
        apiKey: '833cbf062e3b7c3979155c0d07e3f058',
      },
      packagesIndex: {
        FR: 'qa_k8s_products_fr',
        ES: 'qa_k8s_products_es',
      },
      proFormIndex: {
        FR: 'qa_k8s_ProForm_fr',
        ES: 'qa_k8s_ProForm_es',
      },
      firmIndex: 'qa_k8s_firms',
    },
    cloudinary: {
      url: 'https://res.cloudinary.com/quotatis/image/upload',
      app: 'FrontApp',
      api: 'https://api.cloudinary.com/v1_1',
      cloudName: 'quotatis',
      apiSecret: 'HOJZMYzlYfL5CnsOU-b0DiKS6Sk',
      apiKey: '758912458653982',
    },
    bazaarvoice: {
      clientName: 'quotatis',
      siteID: 'main_site',
    },
    defaultLocale: 'fr-FR',
    locales: {
      'fr-FR': fr,
    },
    termsUrl: 'https://conseils-travaux.quotatis.fr/cgu',
    sentry: {
      enabled: false,
      tracesSampleRate: 1,
      replaysSessionSampleRate: 0.5,
      url: 'https://c89422765fbc552fccab7d0faa1fc710@o88364.ingest.us.sentry.io/4507136282001408',
    },
    auth0: {
      domain: 'auth0.qa.quotatis.fr',
    },
    googleVision: {
      url: 'https://vision.googleapis.com/v1/images:annotate',
      apiKey: 'AIzaSyCt2P_oo0MMiWeo8z0feX2F8l4nbm5VLJ0',
      likelihood: {
        UNKNOWN: 0,
        VERY_UNLIKELY: 1,
        UNLIKELY: 2,
        POSSIBLE: 3,
        LIKELY: 4,
        VERY_LIKELY: 5,
      },
      threshold: {
        adult: 4,
        medical: 4,
        racy: 5,
        spoof: 5,
        violence: 4,
      },
    },
    mobileAppIds: {
      android: [
        'fr.quotatispro.quotatis.qa.debug',
        'fr.quotatispro.quotatis.qa',
      ],
      ios: [
        'HQNQ92MT47.fr.quotatis-pro.quotatis.qa',
        'HQNQ92MT47.fr.quotatis-pro.quotatis.qa.debug',
      ],
    },
    coinDesProsApi: {
      timeout: 1500,
      baseURL: ' https://lecoindespros.quotatis.fr/wp-json/wp/v2',
      helpCenterPostCategoryId: 95,
      ttlCache: 86400000, // 1000 * 60 * 60 * 24 = 1 day
    },
  },
  qa1: {
    sylius: {
      url: apiUrls.qa1,
    },
    proApi: {
      url: `${apiUrls.qa1}/pro`,
    },
    hoApi: {
      url: `${apiUrls.qa1}/home-owner`,
    },
    engine: {
      url: apiUrls.qa1,
    },
    billing: {
      url: `${apiUrls.qa1}/billing`,
    },
    documentsApi: {
      url: `${apiUrls.qa1}/documents`,
    },
    frontApi: {
      url: `${apiUrls.qa1}/front-api`,
    },
    survey: {
      url: `${apiUrls.qa1}/survey`,
    },
    algolia: {
      packagesIndex: {
        FR: 'qa1_k8s_products_fr',
        ES: 'qa1_k8s_products_es',
      },
      proFormIndex: {
        FR: 'qa1_k8s_ProForm_prod_fr',
        ES: 'qa1_k8s_ProForm_prod_es',
      },
      firmIndex: 'qa1_k8s_firms',
    },
    intercom: {
      appId: 'swltzwn8',
    },
    sentry: {
      tracesSampleRate: 1,
      replaysSessionSampleRate: 0.5,
      enabled: true,
    },
    locales: {
      'fr-FR': {
        url: 'https://pro.qa1.quotatis.fr',
      },
    },
    auth0: {
      domain: 'auth0.qa1.quotatis.fr',
    },
    firebaseConfig: {
      apiKey: 'AIzaSyANCx6JsR6P6eSodax_dSZSql3kOrCIKbM',
      authDomain: 'fb-carol-qa1.firebaseapp.com',
      databaseURL: 'https://fb-carol-qa1.firebaseio.com',
      projectId: 'fb-carol-qa1',
      storageBucket: 'fb-carol-qa1.appspot.com',
      messagingSenderId: '910036832881',
      appId: '1:910036832881:web:66fea81d638631a9ad01d0',
      measurementId: 'G-5JKH152BLD',
    },
    google: {
      gtagTrackPose: 'G-FMB7PLX0GJ',
    },
    mobileAppIds: {
      android: [
        'fr.quotatispro.quotatis.qa1.debug',
        'fr.quotatispro.quotatis.qa1',
      ],
      ios: [
        'HQNQ92MT47.fr.quotatis-pro.quotatis.qa1',
        'HQNQ92MT47.fr.quotatis-pro.quotatis.qa1.debug',
      ],
    },
  },
  qa: {
    sentry: {
      tracesSampleRate: 1,
      replaysSessionSampleRate: 0.5,
      enabled: true,
    },
    locales: {
      'fr-FR': {
        url: 'https://pro.qa.quotatis.fr',
      },
    },
    auth0: {
      domain: 'auth0.qa.quotatis.fr',
    },
    intercom: {
      appId: 'swltzwn8',
    },
    firebaseConfig: {
      apiKey: 'AIzaSyAZSCHxv2EJakoo_0UqGuhTk_DiAwf7c5g',
      authDomain: 'fb-carol-newqa.firebaseapp.com',
      databaseURL: 'https://fb-carol-newqa.firebaseio.com',
      projectId: 'fb-carol-newqa',
      storageBucket: 'fb-carol-newqa.appspot.com',
      messagingSenderId: '235481442879',
      appId: '1:235481442879:web:d61a9c831f7cace61b668a',
      measurementId: 'G-8XGVPX21FJ',
    },
    google: {
      gtagTrackPose: 'G-FMB7PLX0GJ',
    },
    axeptio: {
      clientId: '61b35df455a3cd4c6b9c48c1',
    },
  },
  preprod: {
    sylius: {
      url: apiUrls.preprod,
    },
    proApi: {
      url: `${apiUrls.preprod}/pro`,
    },
    hoApi: {
      url: `${apiUrls.preprod}/home-owner`,
    },
    engine: {
      url: apiUrls.preprod,
    },
    billing: {
      url: `${apiUrls.preprod}/billing`,
    },
    documentsApi: {
      url: `${apiUrls.preprod}/documents`,
    },
    frontApi: {
      url: `${apiUrls.preprod}/front-api`,
    },
    survey: {
      url: `${apiUrls.preprod}/survey`,
    },
    api: {
      clientId: '21ujn3bgfgjoc8w08s0wgkwgc8s8g4gscggcc4skog8c0g4k8c',
      clientSecret: '5fbpmtbpwkcg0w8gk8ssg44084okg8co4koccssgws8ko0c440',
      url: 'https://api.preprod.quotatis.com',
    },
    google: {
      mapsKey: 'AIzaSyAsFOnh8HufqRe55p_7rCOQG0nxz22px4Q',
      clientId:
        '726740106708-1197l2ohju4kcpod6ugq2k87675t6ibs.apps.googleusercontent.com',
      gtagTrackPose: 'G-MJS0Y4QT1G',
    },
    intercom: {
      appId: 'swltzwn8',
    },
    axeptio: {
      clientId: '61a9e85ef99fa65e9d234778',
    },
    facebook: {
      appId: '297437357370490',
    },
    sentry: {
      tracesSampleRate: 1,
      replaysSessionSampleRate: 0.5,
      enabled: true,
    },
    locales: {
      'fr-FR': {
        url: 'https://pro.preprod.quotatis.fr',
      },
    },
    algolia: {
      packagesIndex: {
        FR: 'preprod_k8s_products_fr',
        ES: 'preprod_k8s_products_es',
      },
      proFormIndex: {
        FR: 'preprod_k8s_ProForm_prod_fr',
        ES: 'preprod_k8s_ProForm_prod_es',
      },
      firmIndex: 'preprod_k8s_firms',
    },
    auth0: {
      domain: 'auth0.preprod.quotatis.fr',
    },
    firebaseConfig: {
      apiKey: 'AIzaSyASnZwYMMYoxZ21OWJORKdXkKaq_DPwu38',
      authDomain: 'fb-carol-newpreprod.firebaseapp.com',
      databaseURL: 'https://fb-carol-newpreprod.firebaseio.com',
      projectId: 'fb-carol-newpreprod',
      storageBucket: 'fb-carol-newpreprod.appspot.com',
      messagingSenderId: '153390929489',
      appId: '1:153390929489:web:64010c4e65ab67a8f9ca70',
      measurementId: 'G-JQDZWD37XC',
    },
    mobileAppIds: {
      android: [
        'fr.quotatispro.quotatis.preprod.debug',
        'fr.quotatispro.quotatis.preprod',
      ],
      ios: [
        'HQNQ92MT47.fr.quotatis-pro.quotatis.preprod',
        'HQNQ92MT47.fr.quotatis-pro.quotatis.preprod.debug',
      ],
    },
  },
  prod: {
    sylius: {
      url: apiUrls.prod,
    },
    proApi: {
      url: `${apiUrls.prod}/pro`,
    },
    hoApi: {
      url: `${apiUrls.prod}/home-owner`,
    },
    engine: {
      url: apiUrls.prod,
    },
    billing: {
      url: `${apiUrls.prod}/billing`,
    },
    documentsApi: {
      url: `${apiUrls.prod}/documents`,
    },
    frontApi: {
      url: `${apiUrls.prod}/front-api`,
    },
    survey: {
      url: `${apiUrls.prod}/survey`,
    },
    api: {
      clientId: '21ujn3bgfgjoc8w08s0wgkwgc8s8g4gscggcc4skog8c0g4k8c',
      clientSecret: '5fbpmtbpwkcg0w8gk8ssg44084okg8co4koccssgws8ko0c440',
      url: 'https://api.prod.quotatis.com',
      kongApiKey: 'qjog89bumjDZfIJVMEUkxgK7jJOrm47y',
    },
    google: {
      mapsKey: 'AIzaSyAsFOnh8HufqRe55p_7rCOQG0nxz22px4Q',
      clientId:
        '726740106708-1197l2ohju4kcpod6ugq2k87675t6ibs.apps.googleusercontent.com',
      gtagTrackPose: 'G-5TB7W82W7C',
    },
    intercom: {
      appId: 'hkrrf3yu',
    },
    axeptio: {
      clientId: '61a9e85ef99fa65e9d234778',
    },
    facebook: {
      appId: '297437357370490',
    },
    sentry: {
      tracesSampleRate: 0.5,
      replaysSessionSampleRate: 0.1,
      enabled: true,
    },
    locales: {
      'fr-FR': {
        url: 'https://pro.quotatis.fr',
      },
    },
    algolia: {
      packagesIndex: {
        FR: 'prod_k8s_products_fr',
        ES: 'prod_k8s_products_es',
      },
      proFormIndex: {
        FR: 'prod_k8s_ProForm_prod_fr',
        ES: 'prod_k8s_ProForm_prod_es',
      },
      firmIndex: 'prod_k8s_firms',
    },
    auth0: {
      domain: 'auth0.prod.quotatis.fr',
    },
    youSign: {
      signatureUis: '/signature_uis/baebb6f6-6304-4f8c-8311-0a0d36f873aa',
    },
    firebaseConfig: {
      apiKey: 'AIzaSyDPjidSWf0ZZHQOaVpkgxcO36kqSJRX7Y4',
      authDomain: 'fb-carol-newprod.firebaseapp.com',
      databaseURL: 'https://fb-carol-newprod.firebaseio.com',
      projectId: 'fb-carol-newprod',
      storageBucket: 'fb-carol-newprod.appspot.com',
      messagingSenderId: '117566274768',
      appId: '1:117566274768:web:8f90956ac647a748b3b2d5',
      measurementId: 'G-4TRXDPB7JM',
    },
    mobileAppIds: {
      android: ['fr.quotatispro.quotatis.debug', 'fr.quotatispro.quotatis'],
      ios: [
        'HQNQ92MT47.fr.quotatis-pro.quotatis',
        'HQNQ92MT47.fr.quotatis-pro.quotatis.debug',
      ],
    },
  },
  development: {
    locales: {
      'fr-FR': {
        url: 'https://pro.local.quotatis.fr:4433',
      },
    },
    auth0: {
      domain: 'auth0.qa.quotatis.fr',
    },
    // Uncomment in order to use engine in local
    // sylius: {
    //   url: 'https://sylius-dev.qarx.io:8090',
    // },
    // proApi: {
    //   url: 'https://pro-api.local.quotatis.com:8204',
    // },
    // engine: {
    //   url: 'https://engine.local.quotatis.com:8205',
    // },
    // billing: {
    //   url: 'https://billing.local.quotatis.com:8207',
    // },
    // documentsApi: {
    //   url: 'https://documents-api.local.quotatis.com:8202',
    // },
    // frontApi: {
    //   url: 'https://front-api.local.quotatis.com:8210',
    // },
    // survey: {
    //   url: 'https://survey.local.quotatis.com:8206',
    // },
  },
  mocks: {
    api: {
      url: `http://${MOCK_HOST}:${MOCK_PORT}`,
    },
    sylius: {
      url: `http://${MOCK_HOST}:${MOCK_PORT}/sylius`,
    },
    proApi: {
      url: `http://${MOCK_HOST}:${MOCK_PORT}/pro-api`,
    },
    engine: {
      url: `http://${MOCK_HOST}:${MOCK_PORT}/engine`,
    },
    billing: {
      url: `http://${MOCK_HOST}:${MOCK_PORT}/billing`,
    },
    documentsApi: {
      url: `http://${MOCK_HOST}:${MOCK_PORT}/documents-api`,
    },
    frontApi: {
      url: `http://${MOCK_HOST}:${MOCK_PORT}/front-api`,
    },
    survey: {
      url: `http://${MOCK_HOST}:${MOCK_PORT}/survey`,
    },
    locales: {
      'fr-FR': {
        url: `http://${MOCK_HOST}:${DEVSRV_PORT}`,
      },
    },
    mocking: true,
    auth0: {
      domain: 'www.local.quotatis.com:7001/auth0',
      clientID: 'ciGnXMY01zOPrxDWlYXob5vDojGpwZUG',
      audience: 'www.local.quotatis.com:7001/auth0/userinfo',
    },
  },
}

const config = merge(configs.all, configs[configs.all.env])

if (process.env.REACT_APP_API_URL) {
  config.api.url = process.env.REACT_APP_API_URL
}

if (process.env.REACT_APP_OVERRIDE_ENV) {
  ;['api', 'algolia'].forEach(key => {
    config[key] = merge(
      configs.all[key],
      configs[process.env.REACT_APP_OVERRIDE_ENV][key],
    )
  })
}

module.exports = config
