import { push } from 'react-router-redux'
import { diff } from 'deep-object-diff'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import qs from 'query-string'
import client from 'services/httpClient/commonClient'
import handleRequest from 'sagas/handleRequest'
import { all, call, put, select, take, race, delay } from 'redux-saga/effects'
import { takeLatest, takeEvery } from 'utils/effects'
import pushGtmEvent from 'utils/gtm'
import translateFactory from 'utils/translateFactory'
import lastIdFromIRI from 'utils/lastIdFromIRI'
import { getUrlCandidatePackage } from 'utils/pro/proTabs'
import {
  TOKEN,
  ADD_USER,
  DELETE_USER,
  MATCHING_OPTION_DEPARTMENT,
  MATCHING_OPTION_RADIUS,
} from 'constants/firm'
import { URLS } from 'constants/urls'
import {
  PRO_PACKAGES_REGISTRATION_PREFIX,
  PRO_REGISTRATION_PREFIX,
  YOUSIGN_IFRAME_CONTAINER_ID,
} from 'constants/pro'
import { CANDIDATE_STATUS_PACKAGE } from 'constants/packageStatus'
import { CONTRACT_PATH } from 'constants/routes'
import { PRO_API } from 'constants/services'
import {
  leadSaleFirms,
  firmDetails,
  firmDetailsByHashId,
  firmUpdate,
  firmTradesDelete,
  firmReviews,
  hashidDecode,
  firmCurrent,
  LEAD_SALE_FIRMS,
  FIRM_DETAILS_BY_HASHID,
  FIRM_DETAILS,
  FIRM_REVIEWS,
  FIRM_UPDATE,
  FIRM_PLACE_AUTO_COMPLETE_LOCATION,
  FIRM_ACTIVE_DIRECTORY_OPTION,
  firmActiveDirectoryOption,
  FIRM_SELECT_DEFAULT_COVER,
  firmCloseAll,
  FIRM_VALIDATE_EMAIL,
  firmValidateEmail,
  firmSendValidateEmail,
  FIRM_SEND_VALIDATE_EMAIL,
  setValidationEmailTokenStatus,
  firmUpdateLegalInfo,
  FIRM_UPDATE_LEGAL_INFO,
  generateFirmContract,
  FIRM_GENERATE_CONTRACT,
  firmGetContract,
  FIRM_GET_CONTRACT,
  FIRM_GET_FILES,
  firmGetSingleFile,
  FIRM_GET_SINGLE_FILE,
  setCandidatePackageValidationError,
  setIsLoginRequest,
  REMOVE_REGISTER_FILE,
  removeRegisterFile,
  FIRM_PRO_DIRECTORY_CONF,
  firmProDirectoryConf,
  UPDATE_FIRM_DETAILS_PRO,
  updateFirmDetails,
  updateFirmUserDetails,
  addFirmUserDetails,
  UPDATE_FIRM_DETAILS,
  UPDATE_FIRM_USER_DETAILS,
  ADD_FIRM_USER_DETAILS,
  UPDATE_NEWSLETTER_OPTINS,
  setSubjectToVat,
  SET_SUBJECT_TO_VAT,
  setUnavailabilityPeriod,
  SET_UNAVAILABILITY_PERIOD,
  deleteUnavailabilityPeriod,
  DELETE_UNAVAILABILITY_PERIOD,
  SET_GEOGRAPHIC_ZONE,
  UPDATE_BANK_INFORMATIONS,
  updateBankInformations,
  getFirmUntilContractSigned,
  GET_FIRM_UNTIL_CONTRACT_SIGNED,
  setGetFirmUntilContractSignedInProgress,
} from './actions'

import { fromContext, fromFirm, fromRouting, fromPro } from '../selectors'
import notify from '../../sagas/notify'
import { setConfiguration } from '../onboarding/actions'
import { isApiEnvProd, isYousignV2Url } from 'utils/url'
import { store } from 'index'

const urlPrefix = '/api'
const LOADED_WIDOW_DOCUMENT_READY_STATE = 'complete'

export function* handleReadLeadSalesFirmsRequest({ firmIri }) {
  const firm = yield select(fromFirm.getId, firmIri)
  if (firm) {
    return
  }

  yield* handleRequest({
    requestActions: leadSaleFirms,
    promise: call(client(PRO_API, true).get, firmIri),
  })
}

export function* handleReadHashidDecodeRequest({ hashid }) {
  yield* handleRequest({
    requestActions: hashidDecode,
    promise: call(client(PRO_API, true).get, `${urlPrefix}/hashid/${hashid}`),
    actionParams: hashid,
  })
}

function* deleteFirmTrades(oldFirmTrades, newFirmTrades) {
  const existingFirmTradesIds = newFirmTrades
    .filter(firmTrade => '@id' in firmTrade)
    .map(firmTrade => firmTrade['@id'])

  yield all([
    ...oldFirmTrades
      .filter(firmTrade => !existingFirmTradesIds.includes(firmTrade['@id']))
      .map(firmTrade =>
        handleRequest({
          requestActions: firmTradesDelete,
          promise: call(client(PRO_API, true).delete, firmTrade['@id']),
        }),
      ),
  ])
}

export function* handleSaveFirmDetailsRequest({ updatedData, oldData }) {
  const id = oldData['@id']
  let firmData = updatedData

  if (updatedData.firmTrades) {
    try {
      yield deleteFirmTrades(oldData.firmTrades, updatedData.firmTrades)
    } catch (e) {
      yield* notify('', 'firm.save.failure.message', 'error')
      return
    }
    firmData = {
      ...updatedData,
      firmTrades: updatedData.firmTrades.map(firmTrade => ({
        ...firmTrade,
        trade: firmTrade.trade['@id'],
      })),
    }
  }

  if (!isEmpty(diff(oldData.firmPictures, updatedData.firmPictures))) {
    const oldFirmPicturesById = {}
    oldData.firmPictures.forEach(firmPicture => {
      oldFirmPicturesById[firmPicture['@id']] = firmPicture
    })
    const firmPicturesPayload = updatedData.firmPictures
      .filter(firmPicture => firmPicture !== undefined && firmPicture !== null)
      .map(firmPicture => {
        const firmPictureId = firmPicture['@id']
        if (
          firmPictureId &&
          isEmpty(diff(oldFirmPicturesById[firmPictureId], firmPicture))
        ) {
          return firmPictureId
        }
        return firmPicture
      })

    firmData = {
      ...firmData,
      firmPictures: firmPicturesPayload,
    }
  } else {
    delete firmData.firmPictures
  }

  try {
    yield* handleRequest({
      requestActions: firmUpdate,
      promise: call(client(PRO_API, true).put, id, firmData),
    })
  } catch (e) {
    yield* notify('', 'firm.save.failure.message', 'error')
    return
  }

  yield* notify('', 'firm.save.success.message')
}

export function* handleSaveFirmLegalDetailsRequest({ data, ...rest }) {
  yield* handleRequest({
    requestActions: firmDetails,
    promise: call(client(PRO_API, true).get, data.firmId),
  })

  const isEmailValidated = yield select(
    fromFirm.getIsEmailValidated,
    data.firmId,
  )

  if (!isEmailValidated) {
    yield put(
      setCandidatePackageValidationError({
        payload: {
          isEmailValidated: false,
        },
      }),
    )

    return yield put(firmUpdateLegalInfo.failure({ notification: false }))
  }

  yield* handleRequest({
    requestActions: firmUpdate,
    promise: call(client(PRO_API, true).put, data.firmId, {
      firmLegalData: data,
    }),
  })

  return yield put(firmUpdateLegalInfo.success(data))
}

function* handleSaveFirmLegalDetailsFailure({ error }) {
  if (error.notification !== false) {
    yield* notify('', 'firm_legal.save.failure.message', 'error')
  }
}

function* handleSaveFirmLegalDetailsSuccess({ payload: { firmId } }) {
  yield* notify('', 'firm_legal.save.success.message')
  yield put(
    generateFirmContract.request({
      firmId: lastIdFromIRI(firmId),
    }),
  )
}

function* handleGenerateContractRequest({ firmId }) {
  yield* handleRequest({
    requestActions: generateFirmContract,
    promise: call(
      client(PRO_API, true).post,
      `/contracts/${firmId}/contract/signature`,
    ),
  })
}

const yousignEventHandler = () => {
  store.dispatch(getFirmUntilContractSigned({ remainingRetries: 3 }))
  store.dispatch(setGetFirmUntilContractSignedInProgress(true))
}

function* handleGenerateContractSuccess() {
  const yousignContractMember = yield select(
    fromFirm.selectYousignContractMember,
  )

  if (typeof yousignContractMember === 'undefined') {
    throw new Error(
      'yousignContractMember must not be undefined on GenerateContractSuccess',
    )
  }

  if (yousignContractMember.signableLink) {
    pushGtmEvent({
      event: 'yousign_sent_package_contract',
      category: 'package_contract_online',
    })
    const signatureLink = yousignContractMember.signableLink

    if (isYousignV2Url(signatureLink)) {
      window.location.href = signatureLink
      return
    }

    const yousignInstance = new Yousign({
      signatureLink,
      iframeContainerId: YOUSIGN_IFRAME_CONTAINER_ID,
      isSandbox: !isApiEnvProd(),
      iFrameAttributes: {
        referrerPolicy: 'origin-when-cross-origin',
      },
    })
    yousignInstance.onSuccess(() => {
      // eslint-disable-next-line no-console
      console.log('Signer has successfully signed the document')
      yousignEventHandler()
    })
    yousignInstance.onDeclined(() => {
      // eslint-disable-next-line no-console
      console.log('Declined - The signer declined the signature')
      yousignEventHandler()
    })
  }
}

function* handleGenerateContractFailure() {
  yield* notify('', 'firm.contract_generation.failure.message', 'error')
}

function* firmGetContractDataRequest({ firmId }) {
  const newWindow = window.open(CONTRACT_PATH)
  yield* handleRequest({
    requestActions: firmGetContract,
    promise: call(
      client(PRO_API, true).get,
      `/contracts/${firmId}/contract.pdf`,
      {
        responseType: 'blob',
      },
    ),
    actionParams: { newWindow },
  })
}

function firmGetContractDataSuccess({ payload, actionParams: { newWindow } }) {
  const file = new Blob([payload], { type: 'application/pdf' })
  const fileURL = window.URL.createObjectURL(file)
  const isIE11 = !!window.MSInputMethodContext && !!document.documentMode
  const isEdge = !isIE11 && !!window.StyleMedia

  if (isIE11) {
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(file)
    }
  } else if (isEdge) {
    const linkEdge = document.createElement('a')
    linkEdge.href = fileURL
    linkEdge.download = file
    linkEdge.click()
  } else {
    /* eslint-disable no-param-reassign */
    newWindow.onload = () => {
      newWindow.location = fileURL
    }
    if (newWindow.document.readyState === LOADED_WIDOW_DOCUMENT_READY_STATE) {
      newWindow.location = fileURL
    }
    /* eslint-ensable no-param-reassign */
  }
}

function firmGetContractDataFailure({ actionParams: { newWindow } }) {
  newWindow.close()
}

export function* handleReadFirmDetailsByHashIdRequest({ hashid }) {
  let id = yield select(fromFirm.getId, hashid)
  if (!id) {
    try {
      yield handleReadHashidDecodeRequest({ hashid })
    } catch (e) {
      const language = yield select(fromContext.getLang)
      const translate = translateFactory(language)
      yield put(
        push({
          pathname: translate('new_url.routes.pro_directory'),
        }),
      )
      return
    }
  }

  id = yield select(fromFirm.getId, hashid)
  yield put(firmCurrent.request(id))
  yield* handleRequest({
    requestActions: firmDetailsByHashId,
    promise: put(firmDetails.request({ id })),
  })
}

export function* handleReadFirmDetailsRequest({ id, isLoginRequest }) {
  yield put(
    setIsLoginRequest({
      isLoginRequest,
    }),
  )
  const firm = yield* handleRequest({
    requestActions: firmDetails,
    promise: call(client(PRO_API, true).get, id),
  })
  yield put({
    type: FIRM_PLACE_AUTO_COMPLETE_LOCATION,
    payload: { city: firm.data.city, postalCode: firm.data.postalCode },
  })
}

export function* handleReadFirmDetailSuccess({
  payload: { packageStatus, contractSignatureStatus },
}) {
  const pathName = yield select(fromRouting.getPathname)
  const isLoginRequest = yield select(fromFirm.getIsLoginRequest)
  const firmId = yield select(fromPro.getFirmId)
  const isFirmConfigured = yield select(fromFirm.getIsFirmConfigured, firmId)

  if (
    packageStatus === CANDIDATE_STATUS_PACKAGE &&
    pathName === URLS.ROUTES.PRO_ACCOUNT &&
    isLoginRequest
  ) {
    yield put(
      push(
        getUrlCandidatePackage(
          contractSignatureStatus,
          isFirmConfigured,
          pathName,
        ),
      ),
    )
  }
}

export function* handleReadFirmReviewsRequest({ id }) {
  yield* handleRequest({
    requestActions: firmReviews,
    promise: call(client(PRO_API, true).get, `${id}/reviews`, {
      params: {
        pagination: false,
        'reviewText[exists]': true,
      },
    }),
  })
}

function* handleActiveDirectoryOptionRequest({ payload }) {
  try {
    yield* handleRequest({
      requestActions: firmActiveDirectoryOption,
      promise: call(
        client(PRO_API, true).post,
        `${urlPrefix}/active-directory-option/${payload}`,
      ),
    })
    yield* notify('', 'firm.save.success.message')
  } catch (e) {
    yield* notify('', 'firm.save.failure.message', 'error')
  }
}

function* handleProDirectoryConfRequest({ firmId, displayedInProDirectory }) {
  try {
    yield* handleRequest({
      requestActions: firmProDirectoryConf,
      promise: call(client(PRO_API, true).put, `${urlPrefix}/firms/${firmId}`, {
        displayedInProDirectory,
      }),
    })
    yield* notify('', 'firm.save.success.message')
  } catch (e) {
    yield* notify('', 'firm.save.failure.message', 'error')
  }
}

function* handleProDirectoryConfSuccess({ payload }) {
  yield put({ type: FIRM_UPDATE.SUCCESS, payload })
}

function* handleActiveDirectoryOptionSuccess({ payload }) {
  yield put({ type: FIRM_UPDATE.SUCCESS, payload })
}

function* handleSelectDefaultCoverRequest(firmData) {
  const id = firmData['@id']

  const data = {
    coverUrl: firmData.coverToUpdate,
  }

  try {
    yield* handleRequest({
      requestActions: firmUpdate,
      promise: call(client(PRO_API, true).put, id, data),
    })
    yield* notify('', 'firm.save.success.message')
    yield put(firmCloseAll())
  } catch (e) {
    yield* notify('', 'firm.save.failure.message', 'error')
  }
}

function* handleValidateEmail() {
  const validateEmailURI = '/api/validate-email/'
  const { token } = qs.parse(window.location.search)
  yield* handleRequest({
    requestActions: firmValidateEmail,
    promise: call(client(PRO_API, true).post, validateEmailURI + token),
  })
}

function* handleValidateEmailSuccess() {
  yield* notify('', 'firm.validate_email.success')
  yield put(setValidationEmailTokenStatus(TOKEN.STATUS.SUCCESS))
}

function* handleValidateEmailFailure({ error }) {
  let tokenStatus = null
  switch (error.response.status) {
    case 400:
      tokenStatus = TOKEN.STATUS.ALREADY_USED
      break
    case 401:
      tokenStatus = TOKEN.STATUS.EXPIRED
      yield* notify('', 'firm.validate_email.token_expired', 'error')
      break
    default:
      tokenStatus = TOKEN.STATUS.ERROR
      yield* notify('', 'firm.validate_email.error', 'error')
  }

  yield put(setValidationEmailTokenStatus(tokenStatus))
}

function* handleSendValidateEmail(firmData) {
  const id = get(firmData, 'payload.0.@id')
  const page = get(firmData, 'payload.1')

  try {
    const sendValidateEmailURI = `${id}/send-validation-email/${page}/resend`
    yield* handleRequest({
      requestActions: firmSendValidateEmail,
      promise: call(client(PRO_API, true).post, sendValidateEmailURI),
    })
    yield* notify('', 'firm.send_validate_email.success')
  } catch (e) {
    yield* notify('', 'firm.send_validate_email.error', 'error')
  }
}

export function* verifyHashId({ hashId }) {
  if (!hashId || typeof hashId !== 'string') {
    return
  }

  const hashOnly = hashId.split('-').reverse()[0]
  const lang = yield select(fromContext.getLocale)

  try {
    const response = yield client(PRO_API, true).get(
      `/api/map-hashid/${hashOnly}`,
      {
        skipAccessToken: true,
        headers: {
          'Accept-Language': lang,
        },
      },
    )

    const language = yield select(fromContext.getLang)
    const translate = translateFactory(language)

    yield put(push(translate('routes.firm', { firmId: response.data })))
  } catch (e) {
    console.info('verifyHashId ERROR', e)
  }
}

function* firmGetFilesDispatch({ payload: firmId }) {
  const firmFiles = yield select(fromFirm.getFirmFileDetails, firmId)
  yield* firmFiles.map(iri => put(firmGetSingleFile.request({ iri })))
}

function* firmGetSingleFileDataRequest({ iri }) {
  yield* handleRequest({
    requestActions: firmGetSingleFile,
    promise: call(client(PRO_API, true).get, iri),
  })
}

const removeRegisterFileRequest = function* ({ idFile }) {
  yield* handleRequest({
    requestActions: removeRegisterFile,
    promise: call(client(PRO_API, true).delete, `/api/firm_files/${idFile}`),
    actionParams: { idFile },
  })
}

const removeRegisterFileSuccess = function* () {
  yield* notify('', 'firm.save.success.message', 'success')
}

const removeRegisterFileFailed = function* () {
  yield* notify('', 'firm.save.failure.message', 'error')
}

const handleFirmDetailsProUpdate = function* ({ payload, firmId, method }) {
  const currentFirmUsers = yield select(fromFirm.getUsers, firmId)
  const usersPayload = currentFirmUsers
  const { userNumber } = payload

  const data = {
    gender: payload.gender,
    firstName: payload.firstName,
    lastName: payload.lastName,
    fixedPhone: payload.fixedPhone,
    mobilePhone: payload.mobilePhone,
  }

  if (
    usersPayload.length > 0 &&
    typeof userNumber !== 'undefined' &&
    userNumber !== null
  ) {
    const firmOwnerId = usersPayload.find(user => user.email === payload.email)[
      '@id'
    ]
    return yield put(updateFirmUserDetails.request({ firmOwnerId, data }))
  }

  if (!userNumber && method === ADD_USER) {
    data.email = payload.email
    return yield put(addFirmUserDetails.request({ firmId, data }))
  }

  if (userNumber && method === DELETE_USER) {
    /* @TODO */
  }

  return true
}

const updateFirmDetailsRequest = function* ({ currentFirmId, data }) {
  yield handleRequest({
    requestActions: updateFirmDetails,
    promise: call(client(PRO_API, true).put, currentFirmId, { ...data }),
  })
}

const updateFirmUserDetailsRequest = function* ({ firmOwnerId, data }) {
  yield handleRequest({
    requestActions: updateFirmUserDetails,
    promise: call(client(PRO_API, true).put, firmOwnerId, { ...data }),
  })
}

const addFirmUserDetailsRequest = function* ({ firmId, data }) {
  const currentFirmCountryCode = yield select(fromFirm.getCountryCode, firmId)

  yield handleRequest({
    requestActions: addFirmUserDetails,
    promise: call(client(PRO_API, true).post, '/api/users', {
      ...data,
      firm: firmId,
      countryCode: currentFirmCountryCode,
    }),
  })
}

const updateSuccess = function* ({ payload }) {
  yield* notify('', 'firm.save.success.message', 'success')

  if (!payload.firm) {
    return
  }
  yield* handleRequest({
    requestActions: firmDetails,
    promise: call(client(PRO_API, true).get, payload.firm),
  })
}

const addFirmUserDefaultsFailure = function* (error) {
  const { data } = error.error.response
  yield* data.violations.map(e => notify('', e.message, 'error'))
}

const updateFailed = function* () {
  yield* notify('', 'firm.save.failure.message', 'error')
}

const handleUpdateNewsletterOptins = function* ({ payload, firmId }) {
  const data = {
    newsletterSubscriptionPartners: payload.newsletterPartnerOptin,
    newsletterSubscriptionQuotatis: payload.newsletterQuotatisOptin,
  }
  yield handleRequest({
    requestActions: updateFirmDetails,
    promise: call(client(PRO_API, true).put, firmId, { ...data }),
  })
}

const handleSetSubjectToVat = function* (data) {
  const firmId = yield select(fromPro.getFirmId)
  const firmLegalData = yield select(fromFirm.getFirmLegalData, firmId)

  return yield* handleRequest({
    requestActions: setSubjectToVat,
    promise: call(client(PRO_API, true).put, firmId, {
      firmLegalData: {
        ...firmLegalData,
        subjectToVat: data.subjectToVat === 'true',
      },
    }),
  })
}

const handleSetGeographicZone = function* (data) {
  const firmId = yield select(fromPro.getFirmId)
  const firmProductSubscriptionId = yield select(
    fromFirm.getFirmProductSubscriptionId,
    firmId,
  )
  const {
    postcodes,
    matchingOption,
    matchingAddress,
    matchingRadius,
    matchingPoint,
  } = data

  yield* handleRequest({
    requestActions: setConfiguration,
    promise: call(client(PRO_API, true).put, firmId, {
      firmProductSubscription: {
        '@id': firmProductSubscriptionId,
        postcodes:
          matchingOption === MATCHING_OPTION_DEPARTMENT ? postcodes : [],
        matchingOption,
        matchingAddress:
          matchingOption === MATCHING_OPTION_RADIUS ? matchingAddress : null,
        matchingRadius:
          matchingOption === MATCHING_OPTION_RADIUS ? matchingRadius : null,
        matchingPoint:
          matchingOption === MATCHING_OPTION_RADIUS ? matchingPoint : null,
      },
    }),
  })

  yield put(firmDetails.request({ id: firmId }))
}

const handleConfigurationUpdateSuccess = function* () {
  yield* notify('', 'firm.save.success.message', 'success')
}

const handleConfigurationUpdateFailure = function* () {
  yield* notify('', 'firm_legal.save.failure.message', 'error')
}

const handleSetUnavailabilityPeriod = function* (data) {
  const firmId = yield select(fromPro.getFirmId)

  return yield* handleRequest({
    requestActions: setUnavailabilityPeriod,
    promise: call(
      client(PRO_API, true).post,
      'api/firm_unavailability_periods',
      {
        firm: firmId,
        startAt: data.startAt,
        endAt: data.endAt,
      },
    ),
  })
}

const handleSetUnavailabilityPeriodSuccess = function* () {
  yield* notify('', 'firm.save.success.message', 'success')
}

const handleSetUnavailabilityPeriodFailure = function* () {
  yield* notify('', 'firm_legal.save.failure.message', 'error')
}

const handleDeleteUnavailabilityPeriod = function* (data) {
  const firmId = yield select(fromPro.getFirmId)

  return yield* handleRequest({
    requestActions: deleteUnavailabilityPeriod,
    promise: call(client(PRO_API, true).delete, data.payload),
    actionParams: { firmId },
  })
}

const handleDeleteUnavailabilityPeriodSuccess = function* () {
  yield* notify('', 'firm.save.success.message', 'success')
}

const handleDeleteUnavailabilityPeriodFailure = function* () {
  yield* notify('', 'firm_legal.save.failure.message', 'error')
}

const handleUpdateBankInformations = function* (data) {
  const firmId = yield select(fromPro.getFirmId)
  const firmLegalDataId = yield select(fromFirm.getFirmLegalData, firmId)

  try {
    yield* handleRequest({
      requestActions: updateBankInformations,
      promise: call(client(PRO_API, true).put, firmId, {
        firmLegalData: {
          '@id': firmLegalDataId['@id'],
          internationalBankAccountNumber:
            data.payload.internationalBankAccountNumber,
          swiftCode: data.payload.swiftCode,
        },
      }),
    })
  } catch (e) {
    yield* notify('', 'firm.save.failure.message', 'error')
  }
}

const handleUpdateBankInformationsSuccess = function* () {
  yield* notify('', 'firm.save.success.message', 'success')
}

const handleGetFirmUntilContractSigned = function* ({ remainingRetries }) {
  const firmId = yield select(fromPro.getFirmId)

  yield put(firmDetails.request({ id: firmId }))

  const [firm, error] = yield race([
    take(FIRM_DETAILS.SUCCESS),
    take(FIRM_DETAILS.FAILURE),
  ])

  if (typeof error !== 'undefined') {
    console.warn(
      'Error while refreshing contractSignatureStatus. Reloading page',
    )
    window.location.href = URLS.ROUTES.CANDIDATE_PACKAGE_CONFIGURATION
    return
  }

  if (firm.payload?.contractSignatureStatus === 'finished') {
    yield put(setGetFirmUntilContractSignedInProgress(false))
    return
  }

  if (remainingRetries > 1) {
    yield delay(1000)
    yield put(
      getFirmUntilContractSigned({ remainingRetries: remainingRetries - 1 }),
    )
    return
  }

  console.warn(
    'Reloading page because expected contract signature status not updated after multiple refreshing attempts',
  )
  window.location.href = URLS.ROUTES.CANDIDATE_PACKAGE_CONFIGURATION
}

export default function* () {
  yield all([
    takeLatest(FIRM_DETAILS.REQUEST, handleReadFirmDetailsRequest),
    takeLatest(FIRM_DETAILS.SUCCESS, handleReadFirmDetailSuccess),
    takeLatest(
      FIRM_DETAILS_BY_HASHID.REQUEST,
      handleReadFirmDetailsByHashIdRequest,
    ),
    takeLatest(FIRM_UPDATE.REQUEST, handleSaveFirmDetailsRequest),
    takeLatest(
      FIRM_UPDATE_LEGAL_INFO.REQUEST,
      handleSaveFirmLegalDetailsRequest,
    ),
    takeLatest(
      FIRM_UPDATE_LEGAL_INFO.FAILURE,
      handleSaveFirmLegalDetailsFailure,
    ),
    takeLatest(
      FIRM_UPDATE_LEGAL_INFO.SUCCESS,
      handleSaveFirmLegalDetailsSuccess,
    ),
    takeLatest(FIRM_REVIEWS.REQUEST, handleReadFirmReviewsRequest),
    takeEvery(LEAD_SALE_FIRMS.REQUEST, handleReadLeadSalesFirmsRequest),
    takeLatest(
      FIRM_ACTIVE_DIRECTORY_OPTION.REQUEST,
      handleActiveDirectoryOptionRequest,
    ),
    takeLatest(FIRM_PRO_DIRECTORY_CONF.REQUEST, handleProDirectoryConfRequest),
    takeLatest(
      FIRM_ACTIVE_DIRECTORY_OPTION.SUCCESS,
      handleActiveDirectoryOptionSuccess,
    ),
    takeLatest(FIRM_PRO_DIRECTORY_CONF.SUCCESS, handleProDirectoryConfSuccess),
    takeLatest(
      FIRM_SELECT_DEFAULT_COVER.REQUEST,
      handleSelectDefaultCoverRequest,
    ),
    takeLatest(FIRM_VALIDATE_EMAIL.REQUEST, handleValidateEmail),
    takeLatest(FIRM_VALIDATE_EMAIL.SUCCESS, handleValidateEmailSuccess),
    takeLatest(FIRM_VALIDATE_EMAIL.FAILURE, handleValidateEmailFailure),
    takeLatest(FIRM_SEND_VALIDATE_EMAIL.REQUEST, handleSendValidateEmail),
    takeLatest(FIRM_GENERATE_CONTRACT.REQUEST, handleGenerateContractRequest),
    takeLatest(FIRM_GENERATE_CONTRACT.SUCCESS, handleGenerateContractSuccess),
    takeLatest(FIRM_GENERATE_CONTRACT.FAILURE, handleGenerateContractFailure),
    takeLatest(FIRM_GET_CONTRACT.REQUEST, firmGetContractDataRequest),
    takeLatest(FIRM_GET_CONTRACT.SUCCESS, firmGetContractDataSuccess),
    takeLatest(FIRM_GET_CONTRACT.FAILURE, firmGetContractDataFailure),
    takeLatest(FIRM_GET_FILES, firmGetFilesDispatch),
    takeEvery(FIRM_GET_SINGLE_FILE.REQUEST, firmGetSingleFileDataRequest),
    takeLatest(REMOVE_REGISTER_FILE.REQUEST, removeRegisterFileRequest),
    takeLatest(REMOVE_REGISTER_FILE.SUCCESS, removeRegisterFileSuccess),
    takeLatest(REMOVE_REGISTER_FILE.FAILURE, removeRegisterFileFailed),
    takeLatest(UPDATE_FIRM_DETAILS_PRO, handleFirmDetailsProUpdate),
    takeLatest(UPDATE_NEWSLETTER_OPTINS, handleUpdateNewsletterOptins),
    takeLatest(UPDATE_FIRM_DETAILS.REQUEST, updateFirmDetailsRequest),
    takeLatest(UPDATE_FIRM_USER_DETAILS.REQUEST, updateFirmUserDetailsRequest),
    takeLatest(ADD_FIRM_USER_DETAILS.REQUEST, addFirmUserDetailsRequest),
    takeLatest(
      [
        UPDATE_FIRM_DETAILS.SUCCESS,
        UPDATE_FIRM_USER_DETAILS.SUCCESS,
        ADD_FIRM_USER_DETAILS.SUCCESS,
      ],
      updateSuccess,
    ),
    takeLatest(ADD_FIRM_USER_DETAILS.FAILURE, addFirmUserDefaultsFailure),
    takeLatest(
      [UPDATE_FIRM_DETAILS.FAILURE, UPDATE_FIRM_USER_DETAILS.FAILURE],
      updateFailed,
    ),
    takeLatest(SET_SUBJECT_TO_VAT.REQUEST, handleSetSubjectToVat),
    takeLatest(SET_GEOGRAPHIC_ZONE.REQUEST, handleSetGeographicZone),
    takeLatest(
      [SET_SUBJECT_TO_VAT.SUCCESS, SET_GEOGRAPHIC_ZONE.SUCCESS],
      handleConfigurationUpdateSuccess,
    ),
    takeLatest(
      [SET_SUBJECT_TO_VAT.FAILURE, SET_GEOGRAPHIC_ZONE.FAILURE],
      handleConfigurationUpdateFailure,
    ),
    takeLatest(
      SET_UNAVAILABILITY_PERIOD.REQUEST,
      handleSetUnavailabilityPeriod,
    ),
    takeLatest(
      SET_UNAVAILABILITY_PERIOD.SUCCESS,
      handleSetUnavailabilityPeriodSuccess,
    ),
    takeLatest(
      SET_UNAVAILABILITY_PERIOD.FAILURE,
      handleSetUnavailabilityPeriodFailure,
    ),
    takeLatest(
      DELETE_UNAVAILABILITY_PERIOD.REQUEST,
      handleDeleteUnavailabilityPeriod,
    ),
    takeLatest(
      DELETE_UNAVAILABILITY_PERIOD.SUCCESS,
      handleDeleteUnavailabilityPeriodSuccess,
    ),
    takeLatest(
      DELETE_UNAVAILABILITY_PERIOD.FAILURE,
      handleDeleteUnavailabilityPeriodFailure,
    ),
    takeLatest(UPDATE_BANK_INFORMATIONS.REQUEST, handleUpdateBankInformations),
    takeLatest(
      UPDATE_BANK_INFORMATIONS.SUCCESS,
      handleUpdateBankInformationsSuccess,
    ),
    takeLatest(
      GET_FIRM_UNTIL_CONTRACT_SIGNED,
      handleGetFirmUntilContractSigned,
    ),
  ])
}
